<template>
<div>
    <div>
        {{ "To be developed" }}
    </div>
</div>
</template>

<script>

export default {
    name: 'Settings',
};
</script>
